@import 'styles/includes.scss';

.RentalTag {
    @extend %labels-small;
    color: $colorBlack;
    box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 9;
    right: 0;
    top: 16px;
    font-weight: bold;
    padding: 13px 22px 12px 22px;

    &--ongoing {
        background-color: $colorBlue50;
    }

    &--planned {
        background-color: $colorBeige50;
    }

    &--done {
        background-color: $colorGreen100;
    }
}
