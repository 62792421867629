@use 'sass:math';

%container {
    margin-left: auto;
    margin-right: auto;
    padding: 0 34px;

    @include media(s) {
        max-width: (map-get($maxwidths, s));
        padding: 0;
    }

    @include media(m) {
        max-width: (map-get($maxwidths, m));
        padding: 0;
    }

    @include media(l) {
        max-width: (map-get($maxwidths, l));
        padding: 0;
    }

    @include media(xl) {
        max-width: (map-get($maxwidths, xl));
        padding: 0;
    }
}

%container-desktop {
    margin-left: auto;
    margin-right: auto;

    @include media(s) {
        max-width: (map-get($maxwidths, s));
        padding: 0;
    }

    @include media(m) {
        max-width: (map-get($maxwidths, m));
        padding: 0;
    }

    @include media(l) {
        max-width: (map-get($maxwidths, l));
        padding: 0;
    }

    @include media(xl) {
        max-width: (map-get($maxwidths, xl));
        padding: 0;
    }
}

%container-text {
    max-width: (map-get($maxwidths, content));
    margin-left: auto;
    margin-right: auto;
    padding: 0 34px;

    @include media(m) {
        padding: 0;
    }

    @include media(l) {
        max-width: (map-get($maxwidths, content));
    }
}

%h1 {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: math.div(34, 24);

    @include media(xl) {
        font-size: 3.3rem;
        line-height: math.div(48, 33);
    }
}

%h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: math.div(32, 20);

    @include media(xl) {
        font-size: 2.4rem;
        line-height: math.div(32, 24);
    }
}

%h3 {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: math.div(24, 18);

    @include media(xl) {
        font-size: 2rem;
        line-height: math.div(24, 20);
    }
}

%h4 {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: math.div(24, 16);
}

%h5 {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: math.div(24, 16);
}

%body-copy {
    font-size: 1.6rem;
    line-height: math.div(25, 16);
    letter-spacing: 0.18px;

    @include media(xl) {
        font-size: 1.8rem;
        line-height: math.div(28, 18);
        letter-spacing: 0.2px;
    }
}

%body-copy-small {
    font-size: 1.4rem;
    line-height: math.div(24, 14);
    letter-spacing: 0.4px;
}

%preamble {
    font-size: 1.6rem;
    line-height: math.div(25, 16);

    @include media(xl) {
        font-size: 1.8rem;
        line-height: math.div(28, 18);
    }
}

%nav-titles {
    font-size: 1.8rem;
}

%nav-titles-small {
    font-size: 1.2rem;
}

%labels-small {
    font-size: 1.1rem;
    letter-spacing: 0.8px;
    text-transform: uppercase;
}

%links {
    font-size: 1.6rem;
    line-height: math.div(28, 16);
    font-weight: 700;
    letter-spacing: 0.18px;
    text-decoration: none;

    @include media(xl) {
        font-size: 1.8rem;
        line-height: math.div(28, 18);
        letter-spacing: 0.2px;
    }
}

%links-small {
    font-size: 1.4rem;
    line-height: math.div(24, 14);
}

%notices {
    font-size: 1.6rem;
    line-height: math.div(24, 16);
    font-weight: bold;

    @include media(m) {
        font-size: 2rem;
        line-height: math.div(24, 20);
    }
}

%button-titles {
    font-size: 1.6rem;
    letter-spacing: 0.4px;
}

%button-titles-small {
    font-size: 1.3rem;
    letter-spacing: 0.2px;
}

%button-small {
    @extend %button-titles-small;
    padding: 7px 25px 7px;
}

%button {
    @extend %links;
    display: inline-block;
    vertical-align: middle;
    transition: all $transition;
    border: none;

    &:focus {
        outline: 2px solid $colorBlue;
        outline-offset: 2px;
    }
}

%button-primary {
    @extend %button-small;
    @extend %button-titles-small;
    background-color: $colorBlue;
    border-radius: 28px;
    color: #fff;

    @include media(m) {
        font-size: 1.6rem;
        letter-spacing: 0.4px;
        padding: 14px 35px;
    }

    &:hover {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
        background-color: $colorBlue900;
        text-decoration: none;
    }

    &:focus {
        box-shadow: none;
        background-color: $colorBlue900;
    }
}

%button-primary-border {
    @extend %button-primary;
    border: solid 2px #fff;
}

%button-primary-inverted {
    @extend %button-primary;
    background-color: #fff;
    color: $colorBlue;

    &:hover {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
        background-color: #efefef;
        text-decoration: none;
    }

    &:focus {
        box-shadow: none;
        background-color: #efefef;
    }
}

%button-secondary {
    @extend %button-small;
    @extend %button-titles-small;
    border-radius: 28px;
    border: solid 2px $colorBlue;
    background-color: inherit;
    font-weight: 500;
    color: $colorBlue;

    @include media(m) {
        font-size: 1.6rem;
        letter-spacing: 0.4px;
        padding: 12px 35px;
    }

    &:hover {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
        background-color: $colorBlue50;
        border-color: $colorBlue900;
        text-decoration: none;
    }
    &:focus {
        box-shadow: none;
        background-color: $colorBlue50;
    }
}

%button-tertiary {
    @extend %button-small;
    @extend %button-titles-small;
    border: 2px solid $colorGreen;
    border-radius: 28px;
    color: $colorGreen900;
    font-weight: normal;
    background-color: #fff;

    @include media(m) {
        font-size: 1.6rem;
        letter-spacing: 0.4px;
        padding: 12px 35px;
    }

    &:hover {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
        background-color: $colorGreen50;
        border-color: $colorGreen900;
        text-decoration: none;
    }

    &:focus {
        box-shadow: none;
        background-color: $colorGreen50;
    }
}

%button-quarternary {
    @extend %button-small;
    @extend %button-titles-small;
    background-color: $colorGreen;
    border-radius: 28px;
    color: #fff;

    @include media(m) {
        font-size: 1.9rem;
        letter-spacing: 0.4px;
        padding: 14px 35px;
        font-weight: bold;
    }

    &:hover {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
        background-color: $colorGreen900;
        text-decoration: none;
    }

    &:focus {
        box-shadow: none;
        background-color: $colorGreen900;
    }
}

%button-disabled {
    background-color: $colorBlack400;
    color: $colorBlack;
    &:hover,
    &:focus {
        background-color: $colorBlack400;
        box-shadow: none;
    }
}
