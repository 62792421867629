@import 'styles/includes.scss';

.Card {
    $root: &;

    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: 32px 0;
    border: 1px solid $colorBlack300;
    background: #fff;
    text-decoration: none;
    transition: all $transition;
    color: $colorBlack;

    @include media(s) {
        flex-flow: row;
    }

    &:hover {
        box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.1);
    }

    &--100 {
        margin-top: 24px;

        @include media(s) {
            min-height: 272px;
        }

        @include media(m) {
            margin-top: 32px;
            margin-bottom: 32px;
        }

        #{$root}__Content {
            justify-content: flex-start;
        }
    }

    &--50 {
        width: 100%;
        margin: 0;
        margin-top: 24px;

        @include media(m) {
            width: calc(50% - 24px);
            min-height: 229px;
            margin-right: 12px;
            margin-left: 12px;
            margin-top: 32px;

            &:last-child {
                margin-right: 0;
            }
        }

        #{$root}__Text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        #{$root}__Content {
            margin: 0;
            padding: 32px 24px;
            justify-content: flex-start;
        }
    }

    &--25 {
        width: 100%;
        margin: 0;
        margin-top: 24px;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;

        @include media(s) {
            width: calc(50% - 24px);
            margin-right: 12px;
            margin-left: 12px;

            &:last-child {
                margin-right: 0;
            }
        }

        @include media(m) {
            width: calc(33% - 24px);
            margin-top: 24px;
        }

        @include media(l) {
            width: calc(25% - 24px);
        }

        #{$root}__Image {
            height: 144px;
            min-height: 144px;
            width: 100%;
        }

        #{$root}__Content {
            @extend %body-copy-small;
            justify-content: space-between;
            width: 100%;
            margin: 0;
            padding: 32px 24px;
            height: 100%;
        }

        #{$root}__Text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    &--CardListHalf {
        margin-bottom: 0;
        border: none;
        min-height: 310px;

        @include media(m) {
            margin-bottom: 32px;
            border: 1px solid $colorBlack300;
        }

        #{$root}__Content {
            padding-left: 32px;
            padding-right: 32px;
        }
    }

    &--Rental {
        margin-bottom: 24px;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        position: relative;
        text-align: left;

        &:nth-child(3) {
            display: none;
        }

        @include media(m) {
            &:nth-child(3) {
                display: block;
            }
        }
        &:not(#{$root}--50, #{$root}--100) {
            margin: 0;
            width: 100%;
            @include media(s) {
                width: calc(50% - 24px);
                margin-right: 12px;
                margin-left: 12px;

                &:last-child {
                    margin-right: 0;
                    width: 100%;
                    flex-flow: row;
                }
            }

            @include media(m) {
                width: calc(33% - 24px);
                margin-bottom: 24px;

                &:last-child {
                    width: calc(33% - 24px);
                    flex-flow: column;
                }
            }
        }

        #{$root}__Image {
            min-height: 180px;
            height: 180px;
            width: 100%;

            img {
                left: 0;
            }

            @include media(m) {
                min-height: 208px;
                height: 208px;
            }
        }

        #{$root}__Content {
            @extend %body-copy-small;
            justify-content: space-between;
            width: 100%;
            margin: 0;
            padding: 32px 24px;
            height: 100%;
        }
    }

    &--Area {
        flex-flow: column;
        width: 100%;
        display: block;
        transition: all $transition;

        @include media(s) {
            width: calc(50% - 24px);
            margin-left: 12px;
            margin-right: 12px;
        }

        @include media(m) {
            width: calc(25% - 24px);
        }

        &:hover {
            text-decoration: underline;
            box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.1);
        }
    }

    &__Image {
        height: 280px;
        width: 100%;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
        }

        @include media(s) {
            min-height: 100%;
            height: 272px;
            height: initial;
            width: calc(50% - 25px);
        }

        #{$root}--Area & {
            width: 100%;
            max-height: 144px;
            min-height: 144px;
        }

        // #{$root}--Rental & {
        //     max-height: 144px;
        //     min-height: 144px;
        // }
    }

    &__Content {
        width: 100%;
        padding: 16px 16px 32px 16px;
        display: flex;
        flex-flow: column;
        justify-content: center;

        @include media(m) {
            padding: 48px 25px 48px 0;
        }

        h3 {
            @extend %h3;
            padding-bottom: 10px;
            position: relative;
        }

        @include media(s) {
            width: 50%;
        }

        #{$root}--Area & {
            width: 100%;
            padding: 30px 24px 73px;
            margin-left: 0;

            #{$root}__Text {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }

    &__Title {
        @extend %h2;
        display: inline-block;
        position: relative;
        margin-right: 20px;
        margin-bottom: 16px;

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 11px;
            right: -20px;
            background-image: url('#{$assetPath}svg/arrow--right-black.svg');
            background-repeat: no-repeat;
            width: 7px;
            height: 12px;
            background-size: contain;
        }
    }

    &__InfoLabel {
        @extend %body-copy-small;
        display: block;
        font-weight: normal;
    }

    &__Text {
        @extend %body-copy-small;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    &__Button {
        margin: 34px auto 0;

        @include media(m) {
            margin-top: 40px;
            margin-left: 0;
        }
    }

    &--IsEven {
        margin-top: 0;

        #{$root}__Image {
            display: none;

            @include media(m) {
                display: block;
            }
        }

        #{$root}__Content {
            background-color: $colorBlack100;
            padding: 62px 34px;
            text-align: left;
            width: 100%;

            @include media(m) {
                width: 50%;
                background-color: #fff;
                padding: 48px 25px 28px 41px;
            }
        }

        @include media(m) {
            flex-direction: row-reverse;

            &:hover {
                box-shadow: none;
            }

            #{$root}__Title {
                &::before {
                    display: none;
                }
            }
            #{$root}__Image {
                min-height: 100%;
                max-height: 100%;
                height: auto;
                width: calc(50% - 25px);
            }
        }
    }
}
