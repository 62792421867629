@import 'styles/includes.scss';

.CardListHalf {
    @extend %container-desktop;
    margin-top: 22px;

    @include media(m) {
        margin-top: 42px;
        margin-bottom: 74px;
    }
}
